/*!
* Modal code
*/
document.addEventListener("DOMContentLoaded", () => {
  this.bsToggles = document.querySelectorAll('[data-bs-toggle="modal"]');
  for (var i = 0; i < this.bsToggles.length; i++) {
    this.bsToggle = this.bsToggles[i];
    this.bsToggle.addEventListener("click", (e) => {
      import(
        /* webpackMode: "lazy" */
        /* webpackPrefetch: true */
        /* webpackChunkName: "modal/modal" */
        './../components/modal/modal.js'
      ).then(modalService => {
        var modal = new modalService.default();
        modal.bsToggle = this.bsToggle;
        modal.initModal(e);
        this.bsToggle.addEventListener('hide.bs.modal', (e) => {
          e.target.classList.remove('btn-spinner');
          this.modal.innerHTML = '';
          this.modal.dispose()
        });
      });
    });
  }

  const hsforms = document.querySelectorAll(".embedhsform");
  if(hsforms.length) {
    import(
      /* webpackMode: "lazy" */
      /* webpackPrefetch: true */
      /* webpackChunkName: "modal/modal" */
      './../components/modal/modal.js'
    ).then(modalService => {
      var modal = new modalService.default();
      modal.loadInlineForm(hsforms);
    });
  }
});
